import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HelmetComponent from '../components/layout/HelmetComponent'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const API_URL =
    process.env.REACT_APP_API_URL ||
    'https://sass-lettre-motivation.vercel.app/'

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(
        `${API_URL}/api/auth/login`,
        {
          email,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Ensure credentials are included
        }
      )
      setMessage('Login successful')
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('loginEmail', email)
      navigate('/generate') // Redirect to the generate cover letter page
    } catch (error) {
      setMessage(
        error.response ? error.response.data.error : 'Error logging in'
      )
    }
  }

  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 py-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded"
          >
            Login
          </button>
        </form>
        {message && <p className="mt-4 text-red-500 text-center">{message}</p>}
      </div>
    </div>
  )
}

export default Login
