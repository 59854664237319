import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import HelmetComponent from '../components/layout/HelmetComponent'

const Account = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [userInfo, setUserInfo] = useState(null)
  const [successMessage, setSuccessMessage] = useState('')
  const API_URL =
    process.env.REACT_APP_API_URL ||
    'https://sass-lettre-motivation.vercel.app/'

  useEffect(() => {
    if (location.state && location.state.message) {
      setSuccessMessage(location.state.message)
      // Clear the state after displaying the message
      navigate(location.pathname, { replace: true, state: {} })
    }
  }, [location, navigate])

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token')
        if (!token) {
          alert('No token found, please login again')
          return
        }
        const response = await axios.get(`${API_URL}/api/user/account`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setUserInfo(response.data)
      } catch (error) {
        console.error('Error fetching user info:', error)
      }
    }

    fetchUserInfo()
  }, [API_URL])

  const handleNavigate = () => {
    navigate('/user-details', { state: { user: userInfo } })
  }

  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <h2 className="text-2xl font-bold mb-6 text-center">My Account</h2>
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
      {userInfo ? (
        <div>
          <h3 className="text-xl font-bold mb-3">Account details:</h3>
          <p>
            <strong>Email:</strong> {userInfo.email}
          </p>
          <p>
            <strong>Role:</strong> {userInfo.role}
          </p>
          <p>
            <strong>Tokens:</strong>{' '}
            {userInfo.role === 'admin' ? 'unlimited' : userInfo.tokens}
          </p>
          <h3 className="text-xl font-bold mb-3 mt-3">
            Information for AI generation
          </h3>
          <p>
            <strong>FirstName:</strong> {userInfo.firstName}
          </p>
          <p>
            <strong>LastName:</strong> {userInfo.lastName}
          </p>
          <p>
            <strong>Status:</strong> {userInfo.status}
          </p>
          <p>
            <strong>Professional Email:</strong> {userInfo.proEmail}
          </p>
          <p>
            <strong>Phone:</strong> {userInfo.phoneNumber}
          </p>
          <p>
            <strong>Experience:</strong> {userInfo.experience}
          </p>
          <p>
            <strong>Skills:</strong>{' '}
            {userInfo.skills.map((skill) => skill).join(', ')}
          </p>
          <p>
            <strong>Hilight:</strong>{' '}
            {userInfo.highlight.map((hilight) => hilight).join(', ')}
          </p>
          <h3 className="text-xl font-bold mb-3 mt-3">Update your details</h3>
          <button
            className="bg-green-500 text-white p-2 rounded mt-4"
            onClick={handleNavigate}
          >
            Update Details
          </button>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default Account
