import React from 'react'
import HelmetComponent from '../components/layout/HelmetComponent'

const Home = () => {
  return (
    <div>
      <HelmetComponent />
      <div className="container mx-auto p-4">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center mb-4">
          <p className="text-2xl font-bold mb-4">
            Welcome to the alpha version of{' '}
            <strong>Cover Letter Generator</strong>! 🎉
          </p>

          <p className="mb-4">
            🔒 Account creation is currently disabled. To log in, please use the
            login and password provided.
          </p>

          <p className="mb-4">
            👤 You can enter your personal details in the{' '}
            <strong>account</strong> menu to save them for multiple letter
            editions.
          </p>

          <p className="mb-4">
            📝 To generate a letter, go to <strong>"Generate Letter"</strong>{' '}
            and fill in the fields.
          </p>

          <p className="mb-4">
            📋 I advise you to copy/paste the text of the job offer you're
            interested in into the "description" field.
          </p>

          <p className="mb-4">
            💸 Be nice to my wallet, generating with GPT-4 costs a kidney, but
            as a beta tester, you've got to try it out too! 😉
          </p>

          <p className="mb-4">
            📧 You can send me feedback by e-mail at{' '}
            <a
              href="mailto:generatecoverletter@gmail.com"
              className="text-blue-500 underline"
            >
              generatecoverletter@gmail.com
            </a>{' '}
            or via{' '}
            <a
              href="https://www.linkedin.com/in/ma%C3%ABl-donnart-09b73b131"
              className="text-blue-500 underline"
            >
              LinkedIn
            </a>
            !
          </p>

          <p className="mt-6">
            🙏 Many thanks for your participation in this test.
          </p>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <p className="text-3xl font-bold mb-6 text-blue-600">What's New?</p>

          <div className="mb-6 w-full">
            <p className="text-2xl font-semibold mb-4 text-gray-700">
              Frontend
            </p>
            <ul className="list-none space-y-2 md:ml-[30%]">
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Improved menu in responsive mode</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Adding pages: Contact, About, and Privacy</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Improved UI</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Better SEO (with Helmet)</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Current status added to all app pages</span>
              </li>
            </ul>
          </div>

          <div className="w-full">
            <p className="text-2xl font-semibold mb-4 text-gray-700">Backend</p>
            <ul className="list-none space-y-2 md:ml-[30%]">
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>
                  Added ability to send mail when your tokens drop to 0
                </span>
              </li>
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Improved prompt</span>
              </li>
              <li className="flex items-center">
                <svg
                  className="w-6 h-6 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z" />
                </svg>
                <span>Update the API</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
