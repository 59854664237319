import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const VerifyEmail = () => {
  const location = useLocation()
  const [message, setMessage] = useState('')

  const API_URL =
    process.env.REACT_APP_API_URL ||
    'https://sass-lettre-motivation.vercel.app/'

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const token = query.get('token')

    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/auth/verify-email?token=${token}`
        )
        setMessage(response.data.message)
      } catch (error) {
        setMessage(error.response.data.error)
      }
    }

    if (token) {
      verifyEmail()
    }
  }, [location.search, API_URL])

  return (
    <div className="container mx-auto p-4">
      <div className="max-w-md mx-auto bg-white shadow-md rounded px-8 py-8">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Email Verification
        </h2>
        <p className="text-center">{message}</p>
      </div>
    </div>
  )
}

export default VerifyEmail
