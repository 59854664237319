import React from 'react'
import HelmetComponent from '../components/layout/HelmetComponent'

const NotFound = () => {
  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded px-8 py-8 text-center">
        <h2 className="text-2xl font-bold mb-6">404 - Page Not Found</h2>
        <p>The page you are looking for does not exist.</p>
        <p>
          Go back to the{' '}
          <a href="/" className="text-blue-500">
            homepage
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default NotFound
