import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import HelmetComponent from '../components/layout/HelmetComponent'

const UserDetails = () => {
  const location = useLocation()
  const user = useMemo(() => location.state?.user || {}, [location.state?.user])

  const navigate = useNavigate()

  const API_URL =
    process.env.REACT_APP_API_URL ||
    'https://sass-lettre-motivation.vercel.app/'

  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '')
  const [proEmail, setProEmail] = useState(user?.proEmail || '')
  const [skills, setSkills] = useState(user?.skills || [''])
  const [highlight, setHighlight] = useState(user?.highlight || [''])
  const [message, setMessage] = useState('')
  const [experience, setExperience] = useState(user?.experience || 0)
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(user?.status || '')

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || '')
      setLastName(user.lastName || '')
      setPhoneNumber(user.phoneNumber || '')
      setProEmail(user.proEmail || '')
      setSkills(user.skills || [''])
      setHighlight(user.highlight || [''])
      setExperience(user.experience || 0)
      setStatus(user.status || '')
    }
  }, [user])

  const handleAddSkill = () => {
    if (skills.length < 10) {
      setSkills([...skills, ''])
    } else {
      setMessage('You can only add 10 skills at max')
    }
  }

  const handleSkillChange = (index, value) => {
    if (value.length <= 20) {
      const newSkills = skills.slice()
      newSkills[index] = value
      setSkills(newSkills)
    } else {
      setMessage('Each skill can only be up to 20 characters long.')
    }
  }

  const handleRemoveSkill = (index) => {
    const newSkills = skills.slice()
    newSkills.splice(index, 1)
    setSkills(newSkills)
  }

  const handleAddHighlight = () => {
    if (highlight.length < 5) {
      setHighlight([...highlight, ''])
    } else {
      setMessage('You can only add 5 highlights at max')
    }
  }

  const handleHighlightChange = (index, value) => {
    if (value.length <= 40) {
      const newHighlight = highlight.slice()
      newHighlight[index] = value
      setHighlight(newHighlight)
    } else {
      setMessage('Each highlight can only be up to 40 characters long.')
    }
  }

  const handleRemoveHighlight = (index) => {
    const newHighlight = highlight.slice()
    newHighlight.splice(index, 1)
    setHighlight(newHighlight)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      if (!token) {
        setMessage('No token found, please login again')
        setLoading(false)
        return
      }
      await axios.post(
        `${API_URL}/api/user/update-user-details`,
        {
          firstName,
          lastName,
          phoneNumber,
          proEmail,
          skills,
          highlight,
          experience,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      navigate('/account', {
        state: { message: 'Details updated successfully' },
      })
      setLoading(false)
    } catch (error) {
      setMessage(
        error.response
          ? error.response.data.error
          : 'Error updating user details'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <h2 className="text-2xl font-bold mb-6 text-center">User Details</h2>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 bg-white p-6 rounded-lg shadow-md"
      >
        <fieldset className="card p-4 mb-4 border rounded-lg shadow-md">
          <legend className="text-lg font-semibold bg-blue-600 text-white rounded px-2">
            Personal Information
          </legend>
          <div className="py-2">
            <label className="block text-gray-700">First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="py-2">
            <label className="block text-gray-700">Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="py-2">
            <label className="block text-gray-700">Phone Number:</label>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="py-2">
            <label className="block text-gray-700">Professional Email:</label>
            <input
              type="email"
              value={proEmail}
              onChange={(e) => setProEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="py-2">
            <label className="block text-gray-700">Your actual status:</label>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
        </fieldset>
        <fieldset className="card p-4 mb-4 border rounded-lg shadow-md">
          <legend className="text-lg font-semibold bg-blue-600 text-white rounded px-2">
            Professional Information
          </legend>
          <div className="py-2">
            <label className="block text-gray-700">Experience: (years)</label>
            <input
              type="number"
              value={experience}
              onChange={(e) => setExperience(Number(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded"
            ></input>
          </div>
          <div className="py-2">
            <label className="block text-gray-700">
              Skills: (10 maximum with a length of 20 characters max.)
            </label>
            {skills.map((skill, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={skill}
                  onChange={(e) => handleSkillChange(index, e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveSkill(index)}
                  className="ml-2 text-red-500"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddSkill}
              className="mt-2 bg-blue-500 text-white p-2 rounded"
            >
              Add Skill
            </button>
          </div>
          <div className="py-2">
            <label className="block text-gray-700">
              Highlight: (5 max. 40 characters long)
            </label>
            {highlight.map((hl, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={hl}
                  onChange={(e) => handleHighlightChange(index, e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveHighlight(index)}
                  className="ml-2 text-red-500"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddHighlight}
              className="mt-2 bg-blue-500 text-white p-2 rounded"
            >
              Add Highlight
            </button>
          </div>
        </fieldset>
        <button
          type="submit"
          className="w-full bg-green-500 text-white p-2 rounded"
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Submit'}
        </button>
      </form>

      {message && (
        <p
          className={`mt-4 text-center ${
            message.includes('Error') ? 'text-red-500' : 'text-green-500'
          }`}
        >
          {message}
        </p>
      )}
    </div>
  )
}

export default UserDetails
