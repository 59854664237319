import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const location = useLocation()
  const menuRef = useRef(null)

  useEffect(() => {
    const token = localStorage.getItem('token')
    setIsAuthenticated(!!token)
  }, [location])

  const handleLogout = () => {
    handleLinkClick()
    localStorage.removeItem('token')
    setIsAuthenticated(false)
    // Add any other logout logic here
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('navbar')
      if (window.innerWidth < 768) {
        // Adjust this value as per your design breakpoint
        if (window.scrollY > 0) {
          navbar.classList.add('sticky', 'top-0', 'z-50')
        } else {
          navbar.classList.remove('sticky', 'top-0', 'z-50')
        }
      } else {
        navbar.classList.remove('sticky', 'top-0', 'z-50')
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const handleLinkClick = () => {
    setIsOpen(false)
  }

  return (
    <nav id="navbar" className="bg-gray-800 p-4 md:static" ref={menuRef}>
      <div className="flex justify-between items-center">
        <div className="text-white text-xl">
          <Link to="/">
            <img
              src="/covletter-logo.webp"
              alt="CovLetter Logo"
              className="h-12 w-12 rounded-full"
            />
          </Link>
        </div>
        <div className="md:hidden">
          <button
            aria-label="Toggle menu"
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? null : 'M4 6h16M4 12h16M4 18h16'}
              ></path>
            </svg>
          </button>
        </div>
        <ul
          className={`md:flex md:items-center md:space-x-6 ${
            isOpen ? 'block' : 'hidden'
          } md:block`}
        >
          <li>
            <Link
              className="text-white block mt-4 md:inline-block md:mt-0"
              to="/"
              onClick={handleLinkClick}
            >
              Home
            </Link>
          </li>
          {isAuthenticated ? (
            <>
              <li>
                <Link
                  className="text-white block mt-4 md:inline-block md:mt-0"
                  to="/generate"
                  onClick={handleLinkClick}
                >
                  Generate Letter
                </Link>
              </li>
              <li>
                <Link
                  className="text-white block mt-4 md:inline-block md:mt-0"
                  to="/my-letters"
                  onClick={handleLinkClick}
                >
                  My letter
                </Link>
              </li>
              <li>
                <Link
                  className="text-white block mt-4 md:inline-block md:mt-0"
                  to="/account"
                  onClick={handleLinkClick}
                >
                  My Account
                </Link>
              </li>
              <li>
                <Link
                  className="text-white block mt-4 md:inline-block md:mt-0"
                  to="/logout"
                  onClick={handleLogout}
                >
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  className="text-white block mt-4 md:inline-block md:mt-0"
                  to="/register"
                  onClick={handleLinkClick}
                >
                  Register
                </Link>
              </li>
              <li>
                <Link
                  className="text-white block mt-4 md:inline-block md:mt-0"
                  to="/login"
                  onClick={handleLinkClick}
                >
                  Login
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      {isOpen && (
        <div className="md:hidden absolute top-0 right-0 p-4">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </nav>
  )
}

export default Menu
