import React from 'react'
import HelmetComponent from '../components/layout/HelmetComponent'

const AboutUs = () => {
  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded px-8 py-8">
        <h2 className="text-2xl font-bold mb-6 text-center">About Us</h2>
        <p>
          Welcome to <strong>CovLetter</strong>! Our mission is to help you
          create the perfect cover letter that stands out to employers. Whether
          you are a recent graduate, a seasoned professional, or someone looking
          to switch careers, our platform provides the tools and resources you
          need to craft a compelling cover letter.
        </p>
        <h3 className="text-xl font-bold mb-4 mt-6">Our Team</h3>
        <p>
          Behind CovLetter, there's a dedicated individual with a deep passion
          for career development and technology. With a unique blend of skills
          in software development, human resources, and career coaching, I am
          committed to helping you craft the perfect cover letter. My mission is
          to empower you in your career journey, providing personalized tools
          and support to help you stand out and achieve your professional goals.
          At CovLetter, you're not just another user – you're a valued
          individual whose success is my utmost priority. Let's create something
          extraordinary together!
        </p>
        <h3 className="text-xl font-bold mb-4 mt-6">Our Mission</h3>
        <p>
          At CovLetter, our mission is simple: to make the process of creating a
          cover letter as easy and effective as possible. We understand the
          challenges of job hunting and aim to provide a seamless experience
          that saves you time and increases your chances of landing your dream
          job.
        </p>
        <h3 className="text-xl font-bold mb-4 mt-6">Contact Us</h3>
        <p>
          If you have any questions, feedback, or need assistance, please do not
          hesitate to reach out to us. You can contact us via our{' '}
          <a
            href="/contact"
            className="text-blue-500 underline underline-offset-1"
          >
            contact form
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default AboutUs
