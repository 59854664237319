import React from 'react'
import HelmetComponent from '../components/layout/HelmetComponent'

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded px-8 py-8">
        <h2 className="text-2xl font-bold mb-6">Privacy Policy</h2>
        <p>
          Welcome to CovLetter. This privacy policy explains how we handle your
          personal data when you use our services.
        </p>
        <h3 className="text-xl font-bold mb-4">Information We Collect</h3>
        <p>
          We collect information that you provide to us directly, such as when
          you create an account, update your profile, or generate cover letters.
        </p>
        <h3 className="text-xl font-bold mb-4">How We Use Your Information</h3>
        <p>
          We use your information to provide, maintain, and improve our
          services, as well as to communicate with you and to personalize your
          experience.
        </p>
        <h3 className="text-xl font-bold mb-4">Sharing Your Information</h3>
        <p>
          We do not share your personal information with third parties except as
          necessary to provide our services, comply with the law, or protect our
          rights.
        </p>
        <h3 className="text-xl font-bold mb-4">Your Choices</h3>
        <p>
          You have the right to access, update, and delete your personal
          information. You can also opt out of certain data collection
          practices.
        </p>
        <h3 className="text-xl font-bold mb-4">Contact Us</h3>
        <p>
          If you have any questions or concerns about our privacy policy, please
          contact us in the{' '}
          <a
            rel="noopener noreferrer"
            className="underline underline-offset-1 text-blue-500"
            href="/contact"
          >
            contact form
          </a>
          .
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
