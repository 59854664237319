import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-5 w-full border-t border-gray-300">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center flex-wrap px-4">
        <p className="text-gray-800 mb-2 md:mb-0">
          &copy; {new Date().getFullYear()} CoverLetterGenerator. All rights
          reserved.
        </p>
        <nav>
          <ul className="list-none p-0 m-0 flex space-x-4">
            <li>
              <a href="/about" className="text-gray-800 hover:underline">
                About Us
              </a>
            </li>
            <li>
              <a href="/contact" className="text-gray-800 hover:underline">
                Contact
              </a>
            </li>
            <li>
              <a href="/privacy" className="text-gray-800 hover:underline">
                Privacy Policy
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
