import axios from 'axios'
import React, { useEffect, useState } from 'react'
import HelmetComponent from '../components/layout/HelmetComponent'

const MyLetters = () => {
  const [coverLetters, setCoverLetters] = useState([])
  const [selectedLetter, setSelectedLetter] = useState(null)

  const API_URL =
    process.env.REACT_APP_API_URL ||
    'https://sass-lettre-motivation.vercel.app/'

  useEffect(() => {
    const fetchCoverLetters = async () => {
      try {
        const token = localStorage.getItem('token')
        if (!token) {
          alert('No token found, please login again')
          return
        }
        const response = await axios.get(
          `${API_URL}/api/letters/get-saved-letters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        setCoverLetters(response.data)
      } catch (error) {
        console.error('Error fetching cover letters:', error)
      }
    }

    fetchCoverLetters()
  }, [API_URL])

  const handleSelectLetter = (letter) => {
    setSelectedLetter(letter)
  }

  const handleCopy = () => {
    if (selectedLetter) {
      const tempElement = document.createElement('div')
      tempElement.innerHTML = selectedLetter.content
      const textContent = tempElement.textContent || tempElement.innerText || ''
      navigator.clipboard.writeText(textContent)
      alert('Cover letter copied to clipboard!')
    }
  }

  return (
    <div className="flex flex-col md:flex-row">
      <HelmetComponent />
      <div className="w-full md:w-1/4 p-4 overflow-y-auto bg-gray-100">
        <h2 className="text-xl font-bold mb-4">My Letters</h2>
        {coverLetters.map((letter) => (
          <div
            key={letter._id}
            className="mb-4 p-2 bg-white shadow-md rounded cursor-pointer"
            onClick={() => handleSelectLetter(letter)}
          >
            <h3 className="font-semibold">{letter.letterName}</h3>
            <p className="text-sm text-gray-600">
              {new Date(letter.createdAt).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
      <div className="w-full md:w-3/4 p-4">
        {selectedLetter ? (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              {selectedLetter.letterName}
            </h2>
            <div
              className="letter-content p-4 bg-white shadow-md rounded"
              dangerouslySetInnerHTML={{ __html: selectedLetter.content }}
            />
            <button
              onClick={handleCopy}
              className="mt-4 bg-green-500 text-white p-2 rounded"
            >
              Copy to Clipboard
            </button>
          </div>
        ) : (
          <p>Select a letter to view its content</p>
        )}
      </div>
    </div>
  )
}

export default MyLetters
