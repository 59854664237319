import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Logout from './components/auth/Logout'
import Footer from './components/layout/Footer'
import Menu from './components/layout/Menu'
import AboutUs from './pages/AboutUs'
import Account from './pages/Account'
import ContactForm from './pages/ContactForm'
import GenerateLetter from './pages/GenerateLetter'
import Home from './pages/Home'
import Login from './pages/Login'
import MyLetters from './pages/MyLetters'
import NotFound from './pages/NotFound'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Register from './pages/Register'
import UserDetails from './pages/UserDetails'
import VerifyEmail from './utils/VerifyEmail'

import './styles.css'

function App() {
  return (
    <Router>
      <div className="w-full bg-gray-300">
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/generate" element={<GenerateLetter />} />
          <Route path="/account" element={<Account />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/user-details" element={<UserDetails />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/my-letters" element={<MyLetters />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

export default App
