const HelmetData = {
  '/': {
    title: 'CovLetter - Home',
    description:
      'Welcome to the Cover Letter Generator! Create professional cover letters effortlessly.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, homepage',
  },
  '/about-us': {
    title: 'CovLetter - About Us',
    description:
      'Learn more about CovLetter, the team behind it, and our mission to help you create the perfect cover letter.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, about us',
  },
  '/contact-us': {
    title: 'CovLetter - Contact Us',
    description:
      'Get in touch with CovLetter, the team behind it, and our mission to help you create the perfect cover letter.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, contact us',
  },
  '/register': {
    title: 'CovLetter - Register',
    description:
      'Register for an account on CovLetter to create and manage your personalized cover letters.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, register',
  },
  '/login': {
    title: 'CovLetter - Login',
    description:
      'Login to your CovLetter account to access your personalized cover letters.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, login',
  },
  '/generate': {
    title: 'CovLetter - Generate',
    description:
      'Create your perfect cover letter with CovLetter, the easy-to-use cover letter generator.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, generate',
  },
  '/privacy': {
    title: 'CovLetter - Privacy Policy',
    description:
      'Learn more about CovLetter, the team behind it, and our mission to help you create the perfect cover letter.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, privacy',
  },
  '/account': {
    title: 'CovLetter - My Account',
    description: 'View your account details and remaining tokens.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, account',
  },
  'user-details': {
    title: 'CovLetter - User Details',
    description: 'View your account details and remaining tokens.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, user details',
  },
  '/contact': {
    title: 'CovLetter - Contact Us',
    description:
      'Get in touch with CovLetter, the team behind it, and our mission to help you create the perfect cover letter.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, contact us',
  },
  '/my-letters': {
    title: 'CovLetter - My Letters',
    description: 'View and manage your saved cover letters.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, my letters',
  },
  '/verify-email': {
    title: 'CovLetter - Verify Email',
    description: 'Verify your email address.',
    image: 'covletter-logo.webp',
    author: 'Maël Donnart',
    robots: 'index, follow',
    keywords:
      'cover letter, cover letter generator, professional cover letter, verify email',
  },
}

export default HelmetData
