import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import HelmetComponent from '../components/layout/HelmetComponent'

const GenerateLetter = () => {
  const [name, setName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [company, setCompany] = useState('')
  const [skills, setSkills] = useState([''])
  const [experience, setExperience] = useState(0)
  const [description, setDescription] = useState('')
  const [language, setLanguage] = useState('English (UK)')
  const [tone, setTone] = useState('Formal and Professional')
  const [coverLetter, setCoverLetter] = useState('')
  const [message, setMessage] = useState('')
  const [highlight, setHighlight] = useState([''])
  const [mail, setMail] = useState('')
  const [phone, setPhone] = useState('')
  const [AIModel, setAIModel] = useState('gpt-4o-mini')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState('')
  const [letterName, setLetterName] = useState('')
  const [userTokens, setUserTokens] = useState(0)

  const API_URL =
    process.env.REACT_APP_API_URL ||
    'https://sass-lettre-motivation.vercel.app/'

  const handleDescriptionChange = (value) => {
    if (value.length < 3000) {
      setDescription(value)
    } else {
      setMessage('Description can only be up to 3000 characters long.')
    }
  }

  const handleAddSkill = () => {
    if (skills.length < 10) {
      setSkills([...skills, ''])
    } else {
      setMessage('You can only add up to 5 skills.')
    }
  }

  const handleSkillChange = (index, value) => {
    if (value.length <= 20) {
      const newSkills = skills.slice()
      newSkills[index] = value
      setSkills(newSkills)
    } else {
      setMessage('Each skill can only be up to 20 characters long.')
    }
  }

  const handleRemoveSkill = (index) => {
    const newSkills = skills.slice()
    newSkills.splice(index, 1)
    setSkills(newSkills)
  }

  const handleAddHighlight = () => {
    if (highlight.length < 5) {
      setHighlight([...highlight, ''])
    } else {
      setMessage('You can only add up to 5 highlights.')
    }
  }

  const handleHighlightChange = (index, value) => {
    if (value.length <= 40) {
      const newHighlight = highlight.slice()
      newHighlight[index] = value
      setHighlight(newHighlight)
    } else {
      setMessage('Each highlight can only be up to 40 characters long.')
    }
  }

  const handleRemoveHighlight = (index) => {
    const newHighlight = highlight.slice()
    newHighlight.splice(index, 1)
    setHighlight(newHighlight)
  }

  const handleMount = useCallback(
    async (loginEmail) => {
      try {
        const token = localStorage.getItem('token')
        if (!token) {
          setMessage('No token found, please login again')
          return
        }
        const response = await axios.get(`${API_URL}/api/user/account`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        setName(`${response.data.firstName} ${response.data.lastName}`)
        setSkills(response.data.skills || [''])
        setHighlight(response.data.highlight || [''])
        setMail(response.data.proEmail || loginEmail)
        setPhone(response.data.phoneNumber)
        setExperience(response.data.experience)
        setUserTokens(response.data.tokens)
        setStatus(response.data.status)
      } catch (error) {
        setMessage(error.response?.data?.error || 'Error fetching user info')
      }
    },
    [API_URL]
  )

  useEffect(() => {
    handleMount()
  }, [handleMount])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      if (!token) {
        setMessage('No token found, please login again')
        setLoading(false)
        return
      }
      const response = await axios.post(
        `${API_URL}/api/letters/generate-cover-letter`,
        {
          name,
          jobTitle,
          company,
          skills,
          experience,
          description,
          language,
          highlight,
          tone,
          mail,
          phone,
          AIModel,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Ensure credentials are included
        }
      )
      const coverLetter = response.data.coverLetter
      setCoverLetter(coverLetter)

      // Enregistrement de la lettre en base de données
      await axios.post(
        `${API_URL}/api/letters/save-letter`,
        {
          letterName: letterName,
          content: coverLetter,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      setMessage('Cover letter generated and saved successfully')
    } catch (error) {
      setMessage(
        error.response
          ? error.response.data.error
          : 'Error generating cover letter'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleCopy = () => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = coverLetter
    const textContent = tempElement.textContent || tempElement.innerText || ''
    navigator.clipboard.writeText(textContent)
    alert('Cover letter copied to clipboard!')
  }

  return (
    <div className="container mx-auto p-4">
      <HelmetComponent />
      <div className="flex flex-col md:flex-row justify-between">
        <div className="form-container w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">Generate Cover Letter</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <fieldset className="p-4 bg-white shadow-md rounded border">
              <legend className="text-lg font-semibold bg-blue-600 text-white rounded px-2">
                Personal Information
              </legend>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 mb-2">
                  Name:
                </label>
                <input
                  type="text"
                  value={name && name !== 'undefined' ? name : ''}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="name"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-700 mb-2">
                  Phone:
                </label>
                <input
                  type="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  id="phone"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="mail" className="block text-gray-700 mb-2">
                  Professional email:
                </label>
                <input
                  type="email"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="mail"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="status" className="block text-gray-700 mb-2">
                  Status:
                </label>
                <input
                  type="text"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="status"
                />
              </div>
            </fieldset>

            <fieldset className="p-4 bg-white shadow-md rounded border">
              <legend className="text-lg font-semibold bg-blue-600 text-white rounded px-2">
                Job Information
              </legend>
              <div className="mb-4">
                <label htmlFor="jobTitle" className="block text-gray-700 mb-2">
                  Job Title:
                </label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="jobTitle"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="company" className="block text-gray-700 mb-2">
                  Your target company:
                </label>
                <input
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="company"
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-gray-700">
                  Job description / job offer : (max 3000 characters)
                </label>
                <textarea
                  value={description}
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  required
                  placeholder="Copy and paste here the job offer you wish to apply for"
                  className="w-full p-2 border border-gray-300 rounded"
                  id="description"
                  maxLength={3000}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="experience"
                  className="block text-gray-700 mb-2"
                >
                  Your experience (years):
                </label>
                <input
                  type="number"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="experience"
                />
              </div>
            </fieldset>

            <fieldset className="p-4 bg-white shadow-md rounded border">
              <legend className="text-lg font-semibold bg bg-blue-600 text-white rounded px-2">
                Skills and Highlights
              </legend>
              <div className="mb-4">
                <label>
                  Skills: (10 maximum with a length of 20 characters max.)
                </label>
                {skills.map((skill, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={skill}
                      onChange={(e) => handleSkillChange(index, e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveSkill(index)}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddSkill}
                  className="mt-2 bg-green-500 text-white p-2 rounded"
                >
                  Add Skill
                </button>
              </div>
              <div className="mb-4">
                <label>Highlight: (5 max. 40 characters long)</label>
                {highlight.map((hl, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={hl}
                      onChange={(e) =>
                        handleHighlightChange(index, e.target.value)
                      }
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveHighlight(index)}
                      className="ml-2 text-red-500"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddHighlight}
                  className="mt-2 bg-green-500 text-white p-2 rounded"
                >
                  Add Highlight
                </button>
              </div>
            </fieldset>

            <fieldset className="p-4 bg-white shadow-md rounded border">
              <legend className="text-lg font-semibold bg-blue-600 text-white rounded px-2">
                Preferences
              </legend>
              <div className="mb-4">
                <label htmlFor="language" className="block text-gray-700 mb-2">
                  Language:
                </label>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="language"
                >
                  <option value="English (UK)">English (UK)</option>
                  <option value="English (US)">English (US)</option>
                  <option value="French">French</option>
                  <option value="German">German</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="tone" className="block text-gray-700 mb-2">
                  Tone:
                </label>
                <select
                  value={tone}
                  onChange={(e) => setTone(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="tone"
                >
                  <option value="Formal and Professional">
                    Formal and Professional
                  </option>
                  <option value="Convincing and Enthusiastic">
                    Convincing and Enthusiastic
                  </option>
                  <option value="Creative and Original">
                    Creative and Original
                  </option>
                </select>
              </div>
              <div className="flex flex-col gap-3 mb-4">
                <label htmlFor="AIModel" className="block text-gray-700 mb-2">
                  Choose AI model:
                </label>
                <select
                  value={AIModel}
                  onChange={(e) => setAIModel(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                  id="AIModel"
                >
                  <option defaultChecked value="gpt-4o-mini">
                    gpt-4o-mini
                  </option>
                  <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                  <option disabled={userTokens < 5} value="gpt-4">
                    {userTokens < 5
                      ? 'Insufficient tokens to using gpt-4'
                      : 'gpt-4'}
                  </option>
                </select>
                {AIModel === 'gpt-3.5-turbo' || AIModel === 'gpt-4o-mini' ? (
                  <p>
                    <strong>
                      For a simple, effective letter: One token per generation
                    </strong>
                  </p>
                ) : (
                  <p>
                    <strong>
                      For a high-quality, highly personalized letter : Five
                      tokens per generation
                    </strong>
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="letterName"
                  className="block text-gray-700 mb-2"
                >
                  Letter Name:
                </label>
                <input
                  type="text"
                  id="letterName"
                  value={letterName}
                  onChange={(e) => setLetterName(e.target.value)}
                  placeholder="Name your cover letter"
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                ></input>
              </div>
            </fieldset>
            <button
              type="submit"
              className="w-full bg-green-500 text-white p-2 rounded"
              disabled={loading}
            >
              {loading ? 'Generating...' : 'Generate'}
            </button>
          </form>
          {message && <p className="mt-4 text-red-500">{message}</p>}
        </div>
        <div className="letter-container w-full md:w-1/2 p-4 bg-gray-100 rounded">
          {coverLetter && (
            <div>
              <h3 className="text-2xl font-bold mb-4">Your Cover Letter</h3>
              <div
                className="letter-box border p-4 bg-white rounded"
                dangerouslySetInnerHTML={{ __html: coverLetter }}
              />
              <button
                onClick={handleCopy}
                className="mt-4 bg-green-500 text-white p-2 rounded"
              >
                Copy to Clipboard
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default GenerateLetter
