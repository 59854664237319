import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import HelmetData from '../../config/HelmetData'

const HelmetComponent = () => {
  //Récupération dynamique de l'URL et des metadatas
  const location = useLocation()
  const baseUrl = 'https://www.covletter.xyz'
  const path = location.pathname
  const url = `${baseUrl}${path}`

  const metadata = HelmetData[path] || {}
  const title = metadata.title
  const description = metadata.description
  const image = metadata.image
  const author = metadata.author
  const robots = metadata.robots
  const keywords = metadata.keywords

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="robots" content={robots} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta property="og:site_name" content="CovLetter" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@CovLetter" />
      <meta name="twitter:creator" content="@CovLetter" />
      <meta name="twitter:domain" content="CovLetter" />
      <meta name="twitter:site_name" content="CovLetter" />
      <meta name="twitter:locale" content="en_US" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

export default HelmetComponent
